import React from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';

interface ChangelogEntry {
  version: string;
  date: string;
  changes: string[];
}

const changelogData: ChangelogEntry[] = [
  {
    version: '1.0',
    date: '19999-28-293',
    changes: [
      'mockup change',
      'mockup change',
      'mockup change',
    ],
  },
  {
    version: '1.0',
    date: '19999-28-293',
    changes: [
      'mockup change',
      'mockup change',
      'mockup change',
    ],
  },
];

export const ChangelogPanel: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-6 space-y-6"
    >
      <h1 className="text-3xl font-bold text-yellow-400">Changelog</h1>
      {changelogData.map((entry, index) => (
        <Card key={index} className="bg-black/50 border-yellow-400/30">
          <CardHeader>
            <CardTitle className="text-xl text-yellow-400">
              Version {entry.version} - {entry.date}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="list-disc list-inside space-y-2">
              {entry.changes.map((change, changeIndex) => (
                <li key={changeIndex}>{change}</li>
              ))}
            </ul>
          </CardContent>
        </Card>
      ))}
    </motion.div>
  );
};