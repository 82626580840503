import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Button } from './ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Switch } from './ui/switch';
import { Badge } from './ui/badge';
import { Clock, Shield, CreditCard } from 'lucide-react';

interface UserProfile {
  username: string;
  displayname?: string;
  subscriptionStatus?: string;
  subscriptionExpiry?: string;
  subscriptionTier?: string;
}

interface SettingsPanelProps {
  userProfile: UserProfile;
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({ userProfile }) => {
  const [user, setUser] = useState<UserProfile>(userProfile);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setUser(userProfile);
  }, [userProfile]);

  const handleSaveProfile = async () => {
    try {
      setLoading(true);
      setError('');
      const token = localStorage.getItem('token');
      const response = await axios.put('https://wax.hij4cked.com/api/user/profile', 
        { displayname: user.displayname },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUser(response.data);
      alert('Profile updated successfully');
    } catch (err) {
      setError('Failed to update profile');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    try {
      setLoading(true);
      setError('');
      const token = localStorage.getItem('token');
      await axios.post('https://wax.hij4cked.com/api/auth/change-password',
        { currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Failed to change password');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="space-y-6 max-w-4xl mx-auto"
    >
      <Tabs defaultValue="profile" className="w-full">
        <TabsList className="grid w-full grid-cols-3 mb-8">
          <TabsTrigger value="profile" className="text-sm sm:text-base">
            <Clock className="w-4 h-4 mr-2" />
            Profile
          </TabsTrigger>
          <TabsTrigger value="security" className="text-sm sm:text-base">
            <Shield className="w-4 h-4 mr-2" />
            Security
          </TabsTrigger>
          <TabsTrigger value="subscription" className="text-sm sm:text-base">
            <CreditCard className="w-4 h-4 mr-2" />
            Subscription
          </TabsTrigger>
        </TabsList>
        <TabsContent value="profile">
          <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-sm">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-yellow-400">Profile Settings</CardTitle>
              <CardDescription className="text-yellow-400/70">
                Manage your account details
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="username" className="text-yellow-400 text-sm font-medium">Username</Label>
                <Input 
                  id="username" 
                  value={user.username} 
                  disabled
                  className="bg-black/50 border-yellow-400/50 text-yellow-400 focus:ring-yellow-400 focus:border-yellow-400" 
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="displayname" className="text-yellow-400 text-sm font-medium">Display Name</Label>
                <Input 
                  id="displayname" 
                  value={user.displayname || ''} 
                  onChange={(e) => setUser({...user, displayname: e.target.value})}
                  className="bg-black/50 border-yellow-400/50 text-yellow-400 focus:ring-yellow-400 focus:border-yellow-400" 
                />
              </div>
              <Button onClick={handleSaveProfile} disabled={loading} className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-200">
                {loading ? 'Saving...' : 'Save Changes'}
              </Button>
              {error && <p className="text-red-500 text-sm">{error}</p>}
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="security">
          <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-sm">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-yellow-400">Security Settings</CardTitle>
              <CardDescription className="text-yellow-400/70">
                Manage your account security
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="currentPassword" className="text-yellow-400 text-sm font-medium">Current Password</Label>
                <Input 
                  id="currentPassword" 
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="bg-black/50 border-yellow-400/50 text-yellow-400 focus:ring-yellow-400 focus:border-yellow-400" 
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="newPassword" className="text-yellow-400 text-sm font-medium">New Password</Label>
                <Input 
                  id="newPassword" 
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="bg-black/50 border-yellow-400/50 text-yellow-400 focus:ring-yellow-400 focus:border-yellow-400" 
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="confirmPassword" className="text-yellow-400 text-sm font-medium">Confirm New Password</Label>
                <Input 
                  id="confirmPassword" 
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="bg-black/50 border-yellow-400/50 text-yellow-400 focus:ring-yellow-400 focus:border-yellow-400" 
                />
              </div>
              <Button onClick={handleChangePassword} disabled={loading} className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-200">
                {loading ? 'Changing Password...' : 'Change Password'}
              </Button>
              {error && <p className="text-red-500 text-sm">{error}</p>}
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="subscription">
          <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-sm">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-yellow-400">Subscription Details</CardTitle>
              <CardDescription className="text-yellow-400/70">
                Manage your subscription
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="bg-yellow-400/10 p-4 rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-yellow-400 font-medium">Current Plan</span>
                  <Badge variant="outline" className="text-yellow-400 border-yellow-400">
                    {user.subscriptionTier || 'N/A'}
                  </Badge>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-yellow-400 font-medium">Status</span>
                  <Badge variant="outline" className="text-green-400 border-green-400">
                    {user.subscriptionStatus || 'N/A'}
                  </Badge>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-yellow-400 font-medium">Expiry Date</span>
                  <span className="text-yellow-400">{user.subscriptionExpiry || 'N/A'}</span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <Label htmlFor="autoRenew" className="text-yellow-400 font-medium">Auto-renew</Label>
                <Switch id="autoRenew" />
              </div>
              <Button className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-200">
                Upgrade Plan
              </Button>
              <Button variant="outline" className="w-full border-yellow-400 text-yellow-400 hover:bg-yellow-400/10 transition-colors duration-200">
                View Billing History
              </Button>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </motion.div>
  );
};