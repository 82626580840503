import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Switch } from './ui/switch';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Badge } from './ui/badge';
import { Loader2, ChevronDown, ChevronUp, Swords, Move, Eye, User, Settings } from 'lucide-react';
import { Button } from './ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Slider } from './ui/slider';

interface Setting {
  name: string;
  value: string | number | boolean;
  type: 'Boolean' | 'Float' | 'Integer' | 'String';
  default: string | number | boolean;
  min?: number;
  max?: number;
}

interface Page {
  name: string;
  settings: string[];
}

interface Module {
  name: string;
  enabled: boolean;
  category: string;
  description: string;
  settings: Record<string, Setting>;
  pages: Page[];
}

interface ModuleListProps {
  modules: Module[];
  sendMessage: (message: string) => void;
  loading: boolean;
}

const categories = ['ALL', 'COMBAT', 'MOVEMENT', 'RENDER', 'PLAYER', 'MISC'] as const;
type Category = typeof categories[number];

const getCategoryIcon = (category: Category) => {
  switch (category) {
    case 'COMBAT':
      return <Swords className="h-4 w-4 text-red-400" />;
    case 'MOVEMENT':
      return <Move className="h-4 w-4 text-green-400" />;
    case 'RENDER':
      return <Eye className="h-4 w-4 text-blue-400" />;
    case 'PLAYER':
      return <User className="h-4 w-4 text-yellow-400" />;
    case 'MISC':
      return <Settings className="h-4 w-4 text-purple-400" />;
    default:
      return null;
  }
};

const ModuleCard: React.FC<{
  module: Module;
  sendMessage: (message: string) => void;
}> = ({ module, sendMessage }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [localModule, setLocalModule] = useState(module);

  const toggleModule = (enabled: boolean) => {
    sendMessage(`${enabled ? 'ENABLE' : 'DISABLE'}_MODULE ${module.name}`);
    setLocalModule(prev => ({ ...prev, enabled }));
  };

  const updateSetting = (settingName: string, value: string | number | boolean) => {
    sendMessage(`SET_SETTING ${module.name} "${settingName}" "${value}"`);
    setLocalModule(prev => ({
      ...prev,
      settings: {
        ...prev.settings,
        [settingName]: { ...prev.settings[settingName], value }
      }
    }));
  };

  const renderSetting = (setting: Setting) => {
    switch (setting.type) {
      case 'Boolean':
        return (
          <Switch
            checked={setting.value as boolean}
            onCheckedChange={(checked) => updateSetting(setting.name, checked)}
          />
        );
      case 'Float':
      case 'Integer':
        return (
          <div className="flex items-center space-x-2">
            <motion.div
              className="w-[120px]"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Slider
                min={setting.min || 0}
                max={setting.max || 100}
                step={setting.type === 'Float' ? 0.1 : 1}
                value={[setting.value as number]}
                onValueChange={(value) => updateSetting(setting.name, value[0])}
                className="w-full"
              />
            </motion.div>
            <motion.span
              className="text-xs font-mono text-yellow-400"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key={`${setting.name}-${setting.value}`}
            >
              {setting.value}
            </motion.span>
          </div>
        );
      case 'String':
        return (
          <Input
            value={setting.value as string}
            onChange={(e) => updateSetting(setting.name, e.target.value)}
            className="bg-black/30 border-yellow-400/30 text-yellow-400"
          />
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-sm hover:bg-black/60 transition-all duration-300">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium text-yellow-400 flex items-center space-x-2">
            {getCategoryIcon(localModule.category as Category)}
            <span>{localModule.name}</span>
          </CardTitle>
          <div className="flex items-center space-x-2">
            <Switch
              checked={localModule.enabled}
              onCheckedChange={toggleModule}
              className="data-[state=checked]:bg-yellow-400"
            />
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-yellow-400 hover:text-yellow-300"
            >
              {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <p className="text-xs text-yellow-400/70 mb-2">{localModule.description}</p>
          <Badge variant="outline" className="text-xs text-yellow-400/50 border-yellow-400/50">
            {localModule.category}
          </Badge>
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-4"
              >
                <Tabs defaultValue={localModule.pages[0].name}>
                  <TabsList className="grid w-full grid-cols-2 mb-4">
                    {localModule.pages.map((page) => (
                      <TabsTrigger key={page.name} value={page.name} className="text-yellow-400">
                        {page.name}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                  {localModule.pages.map((page) => (
                    <TabsContent key={page.name} value={page.name}>
                      <div className="space-y-4">
                        {page.settings.map((settingName) => {
                          const setting = localModule.settings[settingName];
                          return (
                            <motion.div
                              key={settingName}
                              className="flex items-center justify-between"
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <Label htmlFor={settingName} className="text-yellow-400">
                                {settingName}
                              </Label>
                              <div className="w-1/2">{renderSetting(setting)}</div>
                            </motion.div>
                          );
                        })}
                      </div>
                    </TabsContent>
                  ))}
                </Tabs>
              </motion.div>
            )}
          </AnimatePresence>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export const ModulePanel: React.FC<ModuleListProps> = ({ modules, sendMessage, loading }) => {
  const [filter, setFilter] = useState<Category>('ALL');

  const filteredModules = filter === 'ALL' ? modules : modules.filter(m => m.category === filter);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 text-yellow-400 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <motion.h2
        className="text-2xl font-bold text-yellow-400"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Modules
      </motion.h2>
      <motion.div
        className="flex space-x-2 overflow-x-auto pb-2"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        {categories.map((category) => (
          <motion.button
            key={category}
            className={`px-3 py-1 rounded-full text-sm ${
              filter === category ? 'bg-yellow-400 text-black' : 'bg-black/30 text-yellow-400'
            }`}
            onClick={() => setFilter(category)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {category}
          </motion.button>
        ))}
      </motion.div>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <AnimatePresence>
          {filteredModules.map((module) => (
            <ModuleCard key={module.name} module={module} sendMessage={sendMessage} />
          ))}
        </AnimatePresence>
      </motion.div>
      {filteredModules.length === 0 && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center text-yellow-400/70"
        >
          No modules available in this category. Try selecting a different category or check your configuration.
        </motion.p>
      )}
    </div>
  );
};