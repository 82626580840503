import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./components/ui/card"
import { Label } from "./components/ui/label"
import { ChevronRight, Lock, User } from 'lucide-react'

const WaxIcon: React.FC = () => (
  <motion.svg
    width="48"
    height="48"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    animate={{
      filter: ["drop-shadow(0 0 6px #facc15)", "drop-shadow(0 0 12px #facc15)", "drop-shadow(0 0 6px #facc15)"],
    }}
    transition={{
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    }}
  >
    <motion.path
      d="M16 2L4 8L16 14L28 8L16 2Z"
      stroke="#facc15"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse', ease: "easeInOut" }}
    />
    <motion.path
      d="M4 16L16 22L28 16"
      stroke="#facc15"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 2, delay: 0.5, repeat: Infinity, repeatType: 'reverse', ease: "easeInOut" }}
    />
    <motion.path
      d="M4 24L16 30L28 24"
      stroke="#facc15"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 2, delay: 1, repeat: Infinity, repeatType: 'reverse', ease: "easeInOut" }}
    />
  </motion.svg>
);

const InputWrapper: React.FC<{ children: React.ReactNode; icon: React.ReactNode }> = ({ children, icon }) => (
  <div className="relative">
    {children}
    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none text-yellow-400/50">
      {icon}
    </div>
  </div>
);

const Star: React.FC<{ index: number }> = React.memo(() => {
  const style = useMemo(() => ({
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    width: `${Math.random() * 3 + 1}px`,
    height: `${Math.random() * 3 + 1}px`,
  }), []);

  return (
    <motion.div
      className="absolute rounded-full bg-yellow-400"
      style={style}
      animate={{
        scale: [1, 1.5, 1],
        opacity: [0.7, 1, 0.7],
      }}
      transition={{
        duration: Math.random() * 3 + 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }}
    />
  );
});

const StarryBackground: React.FC = React.memo(() => {
  const stars = useMemo(() => {
    return [...Array(100)].map((_, i) => <Star key={i} index={i} />);
  }, []);

  return <>{stars}</>;
});

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://wax.hij4cked.com/api/auth/login', { username, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const memoizedStarryBackground = useMemo(() => <StarryBackground />, []);

  return (
    <div className="flex flex-col min-h-screen bg-black text-white overflow-hidden">
      <main className="flex-grow flex items-center justify-center relative p-4">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-b from-yellow-400/20 to-black opacity-50"></div>
          {memoizedStarryBackground}
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md"
        >
          <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-xl shadow-2xl shadow-yellow-400/20">
            <CardHeader className="space-y-1">
              <motion.div
                className="flex items-center justify-center mb-2"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 260, damping: 20 }}
              >
                <WaxIcon />
              </motion.div>
              <CardTitle className="text-2xl font-bold text-center text-yellow-400">Welcome back</CardTitle>
              <CardDescription className="text-sm text-yellow-400/70 text-center">
                Login to your wax.rest account
              </CardDescription>
            </CardHeader>
            <form onSubmit={handleLogin}>
              <CardContent className="space-y-4 pt-4">
                <div className="space-y-2">
                  <Label htmlFor="username" className="text-yellow-400 text-sm">Username</Label>
                  <InputWrapper icon={<User className="h-4 w-4" />}>
                    <Input 
                      id="username" 
                      type="text" 
                      placeholder="Enter your username" 
                      className="bg-black/50 border-yellow-400/50 text-yellow-400 placeholder:text-yellow-400/50 pl-10 py-2 text-sm" 
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </InputWrapper>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="password" className="text-yellow-400 text-sm">Password</Label>
                  <InputWrapper icon={<Lock className="h-4 w-4" />}>
                    <Input 
                      id="password" 
                      type="password" 
                      placeholder="Enter your password" 
                      className="bg-black/50 border-yellow-400/50 text-yellow-400 placeholder:text-yellow-400/50 pl-10 py-2 text-sm" 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputWrapper>
                </div>
              </CardContent>
              <CardFooter className="flex flex-col space-y-4">
                <Button type="submit" className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-300 text-sm py-2">
                  Login
                  <motion.span
                    animate={{ x: [0, 3, 0] }}
                    transition={{ duration: 1, repeat: Infinity }}
                    className="ml-2"
                  >
                    <ChevronRight className="h-4 w-4" />
                  </motion.span>
                </Button>
                <p className="text-xs text-yellow-400/70 text-center">
                  Don't have an account?{" "}
                  <Link to="/register" className="text-yellow-400 hover:underline font-semibold">
                    Register here
                  </Link>
                </p>
              </CardFooter>
            </form>
          </Card>
        </motion.div>
      </main>
    </div>
  );
};

export default LoginPage;
