import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Zap, BarChart2, Settings, X, Download, FileText, LogOut } from 'lucide-react';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface SidebarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isOpen: boolean;
  toggleSidebar: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, isOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  const tabs = [
    { id: 'modules', icon: Zap, label: 'Modules' },
    { id: 'statistics', icon: BarChart2, label: 'Statistics' },
    { id: 'settings', icon: Settings, label: 'Settings' },
    { id: 'download', icon: Download, label: 'Download' },
    { id: 'changelog', icon: FileText, label: 'Changelog' },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && !isOpen) {
        toggleSidebar();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, toggleSidebar]);

  const handleLogout = async () => {
    try {
      await axios.get('https://wax.hij4cked.com/api/auth/logout', {
        withCredentials: true
      });
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AnimatePresence>
      {(isOpen || window.innerWidth >= 1024) && (
        <motion.div
          className="fixed top-16 left-0 bottom-0 w-64 bg-black/80 backdrop-blur-md border-r border-yellow-400/20 p-4 z-40 flex flex-col"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex justify-end lg:hidden mb-4">
            <Button variant="ghost" size="sm" onClick={toggleSidebar}>
              <X className="h-6 w-6 text-yellow-400" />
            </Button>
          </div>
          <nav className="space-y-2 flex-grow">
            {tabs.map((tab) => (
              <motion.button
                key={tab.id}
                onClick={() => {
                  setActiveTab(tab.id);
                  if (window.innerWidth < 1024) {
                    toggleSidebar();
                  }
                }}
                className={`flex items-center w-full p-2 rounded-lg ${
                  activeTab === tab.id ? 'bg-yellow-400 text-black' : 'text-yellow-400 hover:bg-yellow-400/10'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <tab.icon className="mr-2 h-5 w-5" />
                {tab.label}
              </motion.button>
            ))}
          </nav>
          <motion.button
            className="flex items-center w-full p-2 rounded-lg text-yellow-400 hover:bg-yellow-400/10 mt-auto"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleLogout}
          >
            <LogOut className="mr-2 h-5 w-5" />
            Logout
          </motion.button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};