import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios, { AxiosInstance } from 'axios';
import { useWebSocket } from './hooks/useWebSocket';
import { Sidebar } from './components/Sidebar';
import { Navbar } from './components/Navbar';
import { ModulePanel } from './components/ModulePanel';
import { StatisticsPanel } from './components/StatisticsPanel';
import { SettingsPanel } from './components/SettingsPanel';
import { ChangelogPanel } from './components/ChangeLogPanel';
import { DownloadPanel } from './components/DownloadPanel';
import { useNavigate } from 'react-router-dom';

interface UserProfile {
  username: string;
  displayname?: string;
  admin: number;
  supp: number;
  sub?: Date;
}

interface Statistics {
  userStats: {
    totalUsers: number;
    activeUsers: number;
    bannedUsers: number;
  };
  downloadStats: {
    totalDownloads: number;
    downloadsPerVersion: Array<{ _id: string; downloads: number }>;
    recentDownloads: Array<any>;
    downloadTrend: Array<{ _id: string; count: number }>;
  };
}

const api: AxiosInstance = axios.create({
  baseURL: 'https://wax.hij4cked.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


const Star: React.FC<{ index: number }> = ({ index }) => {
  const style = useMemo(() => ({
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    width: `${Math.random() * 3 + 1}px`,
    height: `${Math.random() * 3 + 1}px`,
  }), []);

  return (
    <motion.div
      className="absolute rounded-full bg-yellow-400"
      style={style}
      animate={{
        scale: [1, 1.5, 1],
        opacity: [0.7, 1, 0.7],
        y: [0, Math.random() * 20 - 10],
      }}
      transition={{
        duration: Math.random() * 3 + 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }}
    />
  );
};

const StarryBackground: React.FC = () => {
  const stars = useMemo(() => {
    return [...Array(50)].map((_, i) => <Star key={i} index={i} />);
  }, []);

  return <>{stars}</>;
};

export const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('modules');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { modules, status, sendMessage, loading: wsLoading } = useWebSocket('ws://localhost:8080');

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const memoizedStarryBackground = useMemo(() => <StarryBackground />, []);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [profileRes, statsRes] = await Promise.all([
          api.get<UserProfile>('/user/profile'),
          api.get<Statistics>('/stats')
        ]);
  
        setUserProfile(profileRes.data);
        setStatistics(statsRes.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            console.log('Unauthorized access, redirecting to login');
            localStorage.removeItem('token'); 
            navigate('/login');
          } else if (err.response?.status === 403) {
            setError('You do not have permission to access this resource');
          } else {
            setError(err.response?.data?.message || 'An error occurred');
          }
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-black text-white overflow-hidden">
      <Navbar toggleSidebar={toggleSidebar} connectionStatus={status} userProfile={userProfile} />
      <div className="flex flex-1 overflow-hidden pt-16">
        <Sidebar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <main className="flex-1 overflow-auto p-4 sm:p-6 lg:ml-64">
          <div className="absolute inset-0 z-0">
            <div className="absolute inset-0 bg-gradient-to-b from-yellow-400/20 to-black opacity-50"></div>
            {memoizedStarryBackground}
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="relative z-10"
            >
              {activeTab === 'modules' && (
                <ModulePanel 
                  modules={modules} 
                  sendMessage={sendMessage} 
                  loading={wsLoading} 
                />
              )}
              {activeTab === 'statistics' && statistics && userProfile && (
                <StatisticsPanel statistics={statistics} userProfile={userProfile} />
              )}
              {activeTab === 'settings' && userProfile && (
                <SettingsPanel userProfile={userProfile} />
              )}
              {activeTab === 'download' && <DownloadPanel />}
              {activeTab === 'changelog' && <ChangelogPanel />}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;