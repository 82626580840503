import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Download, Check, AlertTriangle } from 'lucide-react';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import axios from 'axios';

export const DownloadPanel: React.FC = () => {
  const [currentVersion, setCurrentVersion] = useState("Loading...");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCurrentVersion();
  }, []);

  const fetchCurrentVersion = async () => {
    try {
      const response = await axios.get('https://wax.hij4cked.com/api/download/version');
      setCurrentVersion(response.data.version);
    } catch (err) {
      console.error('Failed to fetch current version:', err);
      setError('Failed to fetch current version');
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://wax.hij4cked.com/api/download', {
        responseType: 'blob',
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `wax.rest-${currentVersion}.exe`);
      document.body.appendChild(link);
      link.click();
      
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
  
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Download failed:', err);
      setError('Download failed. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-6 max-w-2xl mx-auto"
    >
      <Card className="bg-black/50 border-yellow-400/30 overflow-hidden">
        <CardHeader className="bg-yellow-400/10 border-b border-yellow-400/30">
          <CardTitle className="text-2xl font-bold text-yellow-400">Download wax.rest</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6 p-6">
          <div className="flex items-center justify-between">
            <span className="text-gray-300">Current Version:</span>
            <span className="text-yellow-400 font-semibold">{currentVersion}</span>
          </div>
          
          <Button 
            className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-300"
            onClick={handleDownload}
            disabled={isLoading}
          >
            <Download className="mr-2 h-5 w-5" />
            {isLoading ? 'Downloading...' : 'Download Latest Version'}
          </Button>
          
          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}
          
          <div className="space-y-2 text-sm text-gray-400">
            <div className="flex items-center">
              <Check className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" />
              <span>Windows 10 or Windows 11</span>
            </div>
            <div className="flex items-center">
              <Check className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" />
              <span>Visual C++ Redistributable 2015-2022</span>
            </div>
          </div>
          
          <div className="bg-yellow-400/5 border border-yellow-400/20 rounded-lg p-4 text-sm text-gray-300">
            <div className="flex items-start">
              <AlertTriangle className="h-5 w-5 text-yellow-400 mr-2 flex-shrink-0 mt-0.5" />
              <p>
                By downloading, you agree to our Terms of Service and Privacy Policy. 
                Make sure you meet the system requirements before installing.
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};
