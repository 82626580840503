import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Bar, BarChart, CartesianGrid, XAxis, ResponsiveContainer } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "./ui/chart";
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { ErrorBoundary } from 'react-error-boundary';

interface UserProfile {
  username: string;
}

interface Statistics {
  userStats: {
    totalUsers: number;
    activeUsers: number;
    bannedUsers: number;
  };
  downloadStats: {
    totalDownloads: number;
    downloadsPerVersion: Array<{ _id: string; downloads: number }>;
    recentDownloads: Array<any>;
    downloadTrend: Array<{ _id: string; count: number }>;
  };
}

interface StatisticsPanelProps {
  userProfile: UserProfile;
  statistics: Statistics;
}

export const StatisticsPanel: React.FC<StatisticsPanelProps> = ({ userProfile, statistics }) => {
  const stats = [
    { title: 'Total Users', value: statistics.userStats.totalUsers },
    { title: 'Active Users', value: statistics.userStats.activeUsers },
    { title: 'Banned Users', value: statistics.userStats.bannedUsers },
    { title: 'Total Downloads', value: statistics.downloadStats.totalDownloads },
  ];

  const chartData = useMemo(() => {
    return statistics.downloadStats.downloadTrend.map((item) => ({
      date: item._id,
      downloads: item.count,
    }));
  }, [statistics]);

  const [activeChart] = useState<'downloads'>('downloads');

  const chartConfig = {
    downloads: {
      label: "Downloads",
      color: "hsl(var(--chart-1))",
    },
  };

  const total = useMemo(() => ({
    downloads: statistics.downloadStats.totalDownloads,
  }), [statistics]);

  return (
    <div className="space-y-6">
      <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-sm">
        <CardHeader>
          <CardTitle className="text-yellow-400">Welcome, {userProfile.username}</CardTitle>
          <CardDescription className="text-yellow-400/70">
            Here's your statistics overview
          </CardDescription>
        </CardHeader>
      </Card>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {stats.map((stat, index) => (
          <motion.div
            key={stat.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-sm">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium text-yellow-400">{stat.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold text-yellow-400">{stat.value}</div>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>

      <Card className="bg-black/50 border-yellow-400/30 backdrop-blur-sm">
        <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
          <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
            <CardTitle className="text-yellow-400">Download Analytics</CardTitle>
            <CardDescription className="text-yellow-400/70">
              Showing download activity trend
            </CardDescription>
          </div>
          <div className="flex">
            <div
              className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-yellow-400/10 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
            >
              <span className="text-xs text-yellow-400/70">
                Total Downloads
              </span>
              <span className="text-lg font-bold leading-none sm:text-3xl text-yellow-400">
                {total.downloads.toLocaleString()}
              </span>
            </div>
          </div>
        </CardHeader>
        <CardContent className="px-2 sm:p-6">
          <ErrorBoundary fallback={<div className="text-yellow-400">Error loading chart</div>}>
            {chartData.length > 0 ? (
              <ChartContainer
                config={chartConfig}
                className="aspect-auto h-[250px] w-full"
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{
                      left: 12,
                      right: 12,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="date"
                      tickLine={false}
                      axisLine={false}
                      tickMargin={8}
                      minTickGap={32}
                      tickFormatter={(value) => {
                        const date = new Date(value);
                        return date.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        });
                      }}
                    />
                    <ChartTooltip
                      content={
                        <ChartTooltipContent
                          className="w-[150px]"
                          nameKey="downloads"
                          labelFormatter={(value) => {
                            return new Date(value).toLocaleDateString("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            });
                          }}
                        />
                      }
                    />
                    <Bar dataKey="downloads" fill={`var(--color-downloads)`} />
                  </BarChart>
                </ResponsiveContainer>
              </ChartContainer>
            ) : (
              <div className="text-yellow-400">No data available</div>
            )}
          </ErrorBoundary>
        </CardContent>
      </Card>
    </div>
  );
}