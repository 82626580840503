import { useState, useEffect, useCallback } from 'react';

export interface Setting {
  name: string;
  value: string | number | boolean;
  type: 'Boolean' | 'Float' | 'Integer' | 'String';
  default: string | number | boolean;
  min?: number;
  max?: number;
}

export interface Page {
  name: string;
  settings: string[];
}

export interface Module {
  name: string;
  enabled: boolean;
  category: string;
  description: string;
  settings: Record<string, Setting>;
  pages: Page[];
}

type ConnectionStatus = 'connecting' | 'connected' | 'disconnected' | 'error';

export const useWebSocket = (url: string) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [status, setStatus] = useState<ConnectionStatus>('disconnected');
  const [modules, setModules] = useState<Module[]>([]);
  const [loading, setLoading] = useState(true);

  const connect = useCallback(() => {
    const newSocket = new WebSocket(url);

    newSocket.onopen = () => {
      setStatus('connected');
      newSocket.send('GET_ALL_MODULE_DATA');
    };

    newSocket.onclose = () => {
      setStatus('disconnected');
      setTimeout(connect, 5000);
    };

    newSocket.onerror = (error) => {
      setStatus('error');
    };

    newSocket.onmessage = (event) => {
      if (typeof event.data === 'string') {
        if (event.data.startsWith('ALL_MODULE_DATA')) {
          const moduleData = parseAllModuleData(event.data);
          setModules(moduleData);
          setLoading(false);
        } else if (event.data.startsWith('MODULE_ENABLED') || event.data.startsWith('MODULE_DISABLED')) {
          const [action, moduleName] = event.data.split(' ');
          setModules(prevModules =>
            prevModules.map(module =>
              module.name === moduleName ? { ...module, enabled: action === 'MODULE_ENABLED' } : module
            )
          );
        } else if (event.data.startsWith('SETTING_UPDATED')) {
          const [, moduleName, settingName, newValue] = event.data.split(' ');
          setModules(prevModules =>
            prevModules.map(module =>
              module.name === moduleName
                ? {
                    ...module,
                    settings: {
                      ...module.settings,
                      [settingName]: {
                        ...module.settings[settingName],
                        value: parseSettingValue(module.settings[settingName]?.type, newValue),
                      },
                    },
                  }
                : module
            )
          );
        }
      }
    };

    setSocket(newSocket);
  }, [url]);

  useEffect(() => {
    connect();
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [connect]);

  const sendMessage = useCallback((message: string) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    }
  }, [socket]);

  return { modules, status, sendMessage, loading };
};

function parseAllModuleData(data: string): Module[] {
  const moduleDataString = data.substring(data.indexOf('{'));
  const moduleData = JSON.parse(moduleDataString);
  return Object.entries(moduleData).map(([name, data]: [string, any]) => ({
    name,
    enabled: data.enabled,
    category: data.category,
    description: data.description,
    settings: Object.entries(data.settings).reduce((acc, [settingName, settingData]: [string, any]) => {
      acc[settingName] = {
        name: settingName,
        ...settingData,
        value: parseSettingValue(settingData.type, settingData.value),
        min: settingData.min !== undefined ? parseFloat(settingData.min) : undefined,
        max: settingData.max !== undefined ? parseFloat(settingData.max) : undefined,
      };
      return acc;
    }, {} as Record<string, Setting>),
    pages: data.pages,
  }));
}

function parseSettingValue(type: string | undefined, value: string): string | number | boolean {
  if (!type) {
    return value;
  }

  switch (type) {
    case 'Boolean':
      return value.toLowerCase() === 'true';
    case 'Float':
      return parseFloat(value);
    case 'Integer':
      return parseInt(value, 10);
    default:
      return value;
  }
}